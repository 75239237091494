import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Vimeo from '@u-wave/react-vimeo';
import getVideoId from 'get-video-id';
import SbEditable from 'storyblok-react';

import Container from '../Container';
import ColorPicker from '../../helpers/ColorPicker';

//

const Video = ({ content }) => {
  if (!content.VimeoURL) return null;

  const { id } = getVideoId(content.VimeoURL);

  const theme = ColorPicker(content.BgColor, false);

  return (
    <SbEditable content={content}>
      <VideoWrap  themeBackground={theme.background}>
        <VideoContainer>
          <Vimeo video={id}  volume={0.2} responsive />
        </VideoContainer>
      </VideoWrap>
    </SbEditable>
  );
};

export default Video;

const VideoContainer = styled(Container)`
  width: 100%;
  padding: 0;
`;

const VideoWrap = styled.section`
  padding: 3rem;
  background-color: var(--${(props) => props.themeBackground});

  @media (min-width: 640px) {
    padding: 4rem;
  }
`;

Video.propTypes = {
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    VimeoURL: PropTypes.string,
  }),
};

Video.defaultProps = {
  content: {
    BgColor: 'grey--0',
    VimeoURL: null,
  },
};
