import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import SbEditable from 'storyblok-react';

import Container from '../Container';
import ColorPicker from '../../helpers/ColorPicker';
import Shape from '../Shape';
import Button from '../Button';

//

const HeaderBlock = ({ content }) => {
  const theme = ColorPicker(content.BgColor, content.AltColors);

  const fluidProps = getFluidGatsbyImage(content.Image.filename, {
    maxWidth: 1080,
  });

  return (
    <SbEditable content={content}>
      <HeaderBlockWrap
        themeBackground={theme.background}
        themeDivider={theme.divider}
        themeText={theme.text}
        themeHighlight={theme.highlight}
      >
        <HeaderBlockContainer flip={content.Flip}>
          <div className="content">
            {content.Subtitle && <h3>{content.Subtitle}</h3>}
            {content.Headline && <h2>{content.Headline}</h2>}
            {content.Copy && <p>{content.Copy}</p>}
            {content.Button && content.Button.length > 0 && (
              <Button
                content={{
                  label: content.Button[0].label,
                  to: content.Button[0].to,
                  arrow: content.Button[0].arrow,
                }}
                customTheme={theme}
              />
            )}
          </div>

          <div className="image">
            <div className="image--wrap">
              <Img fluid={fluidProps} alt={content.Image.alt} />
              {content.ImageBackgroundShape && (
                <BgShape
                  shape={content.ImageBackgroundShape}
                  color={theme.highlight}
                />
              )}
            </div>
          </div>
        </HeaderBlockContainer>
      </HeaderBlockWrap>
    </SbEditable>
  );
};

export default HeaderBlock;

const HeaderBlockContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  direction: ${(props) => (props.flip ? 'rtl' : 'ltr')};

  > * {
    direction: ltr;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const HeaderBlockWrap = styled.section`
  padding: 3rem;
  background-color: var(--${(props) => props.themeBackground});
  color: var(--${(props) => props.themeText});

  @media (min-width: 640px) {
    padding: 4.2rem;
  }

  .content {
    width: 100%;

    @media (min-width: 768px) {
      width: 50%;
    }

    h3 {
      margin-bottom: 1.4rem;
      color: var(--${(props) => props.themeHighlight});
      font-size: 1.4rem;
      line-height: 2rem;
    }

    h2 {
      font-family: var(--font-serif);
      font-size: 3.2rem;
      line-height: 3.8rem;
      font-weight:700;

      @media (min-width: 1024px) {
        font-size: 4.2rem;
        line-height: 4.8rem;
      }
    }

    p {
      margin-top: 2.4rem;
      font-size: 1.4rem;
      line-height: 1.8rem;

      @media (min-width: 1024px) {
        font-size: 1.8rem;
        line-height: 2.4rem;
      }
    }

    button {
      margin-top: 6rem;
    }
  }

  .image {
    width: 100%;
    margin-top: 2.4rem;

    @media (min-width: 768px) {
      width: calc(50% - 3.2rem);
      margin-top: unset;
    }

    .gatsby-image-wrapper {
      position: relative;
      z-index: 15;
    }

    .image--wrap {
      position: relative;
    }
  }
`;

const BgShape = styled(Shape)`
  display: flex;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0.1;
`;

HeaderBlock.propTypes = {
  content: PropTypes.shape({
    BgColor: PropTypes.string,
    AltColors: PropTypes.bool,
    Subtitle: PropTypes.string,
    Headline: PropTypes.string,
    Copy: PropTypes.string,
    Button: PropTypes.array,
    Image: PropTypes.object,
    ImageBackgroundShape: PropTypes.string,
    Flip: PropTypes.bool,
  }),
};

HeaderBlock.defaultProps = {
  content: {
    BgColor: 'white',
    AltColors: false,
    Subtitle: null,
    Headline: null,
    Copy: null,
    Button: null,
    Image: null,
    ImageBackgroundShape: null,
    Flip: false,
  },
};
